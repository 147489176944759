import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, previewImage }) {
  const { site } = useStaticQuery(
    graphql`query {
      site {
        siteMetadata {
          defaultPreviewImage
          description
          title
          twitter
        }
      }
    }`
  )

  var metaTitle = title
  var metaTitleTemplate = '%s'
  if (metaTitle) {
    metaTitleTemplate = site.siteMetadata.title + ' | ' + metaTitleTemplate
  } else {
    metaTitle = site.siteMetadata.title
  }

  const metaDesc = description || site.siteMetadata.description

  var metaImage = process.env.BASE_URL
  if (previewImage) {
    metaImage += previewImage
  } else {
    metaImage += site.siteMetadata.defaultPreviewImage
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={metaTitleTemplate}
      meta={[
        {
          name: 'description',
          content: metaDesc,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDesc,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.twitter,
        },
        {
          name: 'twitter:site',
          content: site.siteMetadata.twitter,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:image',
          content: metaImage,
        },
        {
          name: 'twitter:description',
          content: metaDesc,
        },
      ].concat(meta)}
    >
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  previewImage: PropTypes.string,
}

export default SEO
